import * as React from "react";

import "./style.css";
import "../i18n";

import {useTranslation} from "react-i18next";
import Layout from "../components/Layout/Layout";
import * as styles from "./talk-to-us.module.css";
import Seo from "../components/shared/Seo";

export default function TalkToUs() {
    const {t} = useTranslation();

    return (
        <Layout>
            <Seo title="Contact us"
                 description="Reach out to us for a personalised demo, custom options, and any questions about pricing."
                 canonicalPath="/talk-to-us"/>
            <section className={styles.talk_to_us_container}>
                <div className={styles.contactForm}>
                    <iframe
                        id={"Iframe"}
                        src={t("talk-to-us-page.form")}
                        scrolling="no"
                        seamless="seamless"
                    />
                </div>
            </section>
        </Layout>
    );
}
